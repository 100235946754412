import React from 'react';
import '../styles/footer.css';
import { ReactComponent as IconMenu } from '../imagenes/altrips-03.svg';
import { useTranslation } from "react-i18next";

export function Footer(){
    const { t } = useTranslation();
    return(
        <div className="container-fluid div-principal-banner bg-dark text-white-50 py-5 px-sm-3 px-lg-5">
            <div className="row pt-3">
                <div className="col-lg-3 col-md-6 mb-5 align-self-lg-center">
                    {/* <a href="" className="navbar-brand logo-footer">
                        <IconMenu />
                    </a> */}
                </div>
                <div className="col-lg-3 col-md-6 mb-5">
                    {/* <h5 className="text-white text-uppercase mb-4 letter">Our Services</h5>
                    <div className="d-flex flex-column justify-content-start">
                        <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Guides</a>
                        <a className="text-white-50 mb-2" href="#"><i className="fa fa-angle-right mr-2"></i>Testimonial</a>
                        <a className="text-white-50" href="#"><i className="fa fa-angle-right mr-2"></i>Blog</a>
                    </div> */}
                </div>
                <div className="col-lg-3 col-md-6 mb-5">
                    <h5 className="text-white text-uppercase mb-4 letter">{t("footer.terms_and_conditions")}</h5>
                    <div className="d-flex flex-column justify-content-start">
                        <a className="text-white-50 mb-2" href="/About"><i className="fa fa-angle-right mr-2"></i>{t("footer.about")}</a>
                        <a className="text-white-50 mb-2" href="/Terms"><i className="fa fa-angle-right mr-2"></i>{t("footer.terms")}</a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-5">
                    <h5 className="text-white text-uppercase mb-4 letter">Contact Us</h5>
                    <p><i className="fa fa-map-marker-alt mr-2"></i>Cancun, México</p>
                    <p><i className="fa fa-phone-alt mr-2"></i>+52 1 998 844 6385</p>
                    <p><i className="fa fa-phone-alt mr-2"></i>+52 1 998 394 8514</p>
                    <p><i className="fa fa-envelope mr-2"></i>info@altripscancun.com</p>
                    <h6 className="text-white text-uppercase mt-4 mb-3 letter">Newsletter</h6>
                    <div >
                        <div className="input-group">
                            <input type="text" className="form-control border-light inpt-form-banner" placeholder="Your Email" />
                            <div className="input-group-append">
                                <button className="btn btn-primary px-3">Sign Up</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}